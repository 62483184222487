'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Css = require("bs-css/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fonts$Paulshen = require("../constants/Fonts.bs.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");

var root = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.border(Css.px(1), Css.solid, Colors$Paulshen.primary400),
        /* [] */0
      ]
    ]);

var sideLabel = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary300),
      /* :: */[
        Css.fontSize(Css.px(12)),
        /* :: */[
          Css.fontFamily(Fonts$Paulshen.mono),
          /* :: */[
            Css.letterSpacing(Css.pxFloat(0.5)),
            /* :: */[
              Css.textTransform(Css.uppercase),
              /* :: */[
                Css.padding3(Css.px(10), Css.px(6), Css.px(4)),
                /* :: */[
                  Css.unsafe("writingMode", "vertical-lr"),
                  /* :: */[
                    Css.unsafe("textOrientation", "upright"),
                    /* :: */[
                      Css.borderRight(Css.px(1), Css.solid, Colors$Paulshen.primary400),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var table = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* [] */0
      ]
    ]);

var tableRow = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.borderBottom(Css.px(1), Css.solid, Colors$Paulshen.primary400),
        /* :: */[
          Css.minHeight(Css.px(32)),
          /* :: */[
            Css.lastChild(/* :: */[
                  Css.borderBottomStyle(Css.none),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var tableLabel = Css.style(/* :: */[
      Css.width(Css.pct(25)),
      /* :: */[
        Css.padding3(Css.px(9), Css.px(12), Css.px(7)),
        /* :: */[
          Css.borderRight(Css.px(1), Css.solid, Colors$Paulshen.primary400),
          /* :: */[
            Css.fontFamily(Fonts$Paulshen.mono),
            /* :: */[
              Css.color(Colors$Paulshen.primary300),
              /* :: */[
                Css.textTransform(Css.uppercase),
                /* :: */[
                  Css.fontSize(Css.px(12)),
                  /* :: */[
                    Css.display(Css.flexBox),
                    /* :: */[
                      Css.alignItems(Css.center),
                      /* :: */[
                        Css.letterSpacing(Css.pxFloat(0.5)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var tableValue = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.alignItems(Css.center),
        /* :: */[
          Css.fontSize(Css.px(12)),
          /* :: */[
            Css.flexGrow(1),
            /* :: */[
              Css.padding2(Css.px(8), Css.px(12)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  root: root,
  sideLabel: sideLabel,
  table: table,
  tableRow: tableRow,
  tableLabel: tableLabel,
  tableValue: tableValue
};

function MonoTable$Row(Props) {
  var label = Props.label;
  var value = Props.value;
  var match = Props.children;
  var children = match !== undefined ? Caml_option.valFromOption(match) : null;
  return React.createElement("div", {
              className: tableRow
            }, label !== undefined ? React.createElement("div", {
                    className: tableLabel
                  }, Caml_option.valFromOption(label)) : null, value !== undefined ? React.createElement("div", {
                    className: tableValue
                  }, Caml_option.valFromOption(value)) : null, children);
}

var Row = {
  make: MonoTable$Row
};

function MonoTable(Props) {
  var label = Props.label;
  var children = Props.children;
  var className = Props.className;
  return React.createElement("div", {
              className: Cn.make(/* :: */[
                    root,
                    /* :: */[
                      Cn.unpack(className),
                      /* [] */0
                    ]
                  ])
            }, React.createElement("div", {
                  className: sideLabel
                }, label), React.createElement("div", {
                  className: table
                }, children));
}

var make = MonoTable;

exports.Styles = Styles;
exports.Row = Row;
exports.make = make;
/* root Not a pure module */
