'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Grid$Paulshen = require("../components/Grid.bs.js");
var Page$Paulshen = require("../ui/Page.bs.js");
var Text$Paulshen = require("../ui/Text.bs.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");
var MonoTable$Paulshen = require("../ui/MonoTable.bs.js");
var MediaQuery$Paulshen = require("../constants/MediaQuery.bs.js");
var WindowDimensions$Paulshen = require("../components/WindowDimensions.bs.js");

var body = Css.style(/* :: */[
      Css.maxWidth(Css.px(896)),
      /* :: */[
        Css.marginLeft(Css.auto),
        /* :: */[
          Css.marginRight(Css.auto),
          /* [] */0
        ]
      ]
    ]);

var rightPane = Css.style(/* :: */[
      Css.paddingTop(Css.px(38)),
      /* [] */0
    ]);

var metadata = Css.style(/* :: */[
      Css.maxWidth(Css.px(512)),
      /* :: */[
        Css.marginTop(Css.px(48)),
        /* [] */0
      ]
    ]);

var metadataLabel = Css.style(/* :: */[
      Css.fontSize(Css.px(10)),
      /* :: */[
        Css.padding(Css.px(4)),
        /* :: */[
          Css.unsafe("writingMode", "vertical-lr"),
          /* :: */[
            Css.unsafe("textOrientation", "upright"),
            /* :: */[
              Css.borderRight(Css.px(1), Css.solid, Colors$Paulshen.primary300),
              /* :: */[
                Css.textAlign(Css.center),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var metadataGrid = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.fontSize(Css.px(12)),
        /* [] */0
      ]
    ]);

var metadataGridRow = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.borderBottom(Css.px(1), Css.solid, Colors$Paulshen.primary300),
        /* :: */[
          Css.lastChild(/* :: */[
                Css.borderBottomStyle(Css.none),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var metadataGridLabel = Css.style(/* :: */[
      Css.width(Css.pct(25)),
      /* :: */[
        Css.padding2(Css.px(6), Css.px(12)),
        /* :: */[
          Css.borderRight(Css.px(1), Css.solid, Colors$Paulshen.primary300),
          /* [] */0
        ]
      ]
    ]);

var metadataGridValue = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.padding2(Css.px(6), Css.px(12)),
        /* [] */0
      ]
    ]);

var text = Css.style(/* :: */[
      Css.maxWidth(Css.px(512)),
      /* :: */[
        Css.paddingRight(Css.px(32)),
        /* :: */[
          Css.marginBottom(Css.px(32)),
          /* :: */[
            MediaQuery$Paulshen._840(/* :: */[
                  Css.paddingRight(Css.zero),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var textSection = Css.style(/* :: */[
      Css.marginTop(Css.px(32)),
      /* [] */0
    ]);

var textList = Css.style(/* :: */[
      Css.margin(Css.zero),
      /* :: */[
        Css.padding4(Css.zero, Css.zero, Css.zero, Css.px(18)),
        /* [] */0
      ]
    ]);

var Styles = {
  body: body,
  rightPane: rightPane,
  metadata: metadata,
  metadataLabel: metadataLabel,
  metadataGrid: metadataGrid,
  metadataGridRow: metadataGridRow,
  metadataGridLabel: metadataGridLabel,
  metadataGridValue: metadataGridValue,
  text: text,
  textSection: textSection,
  textList: textList
};

function AboutPage(Props) {
  React.useContext(WindowDimensions$Paulshen.context);
  return React.createElement(Page$Paulshen.make, {
              children: React.createElement("div", {
                    className: body
                  }, React.createElement(Grid$Paulshen.Root.make, {
                        children: null
                      }, React.createElement(Grid$Paulshen.Cell.make, {
                            span: 7,
                            children: React.createElement("div", {
                                  className: text
                                }, React.createElement(Text$Paulshen.Body.make, {
                                      children: null
                                    }, React.createElement("p", undefined, "Hi! I'm Paul."), React.createElement("p", undefined, "I'm an engineer in San Francisco with a passion for design and technology. I've previously worked at Discord and Facebook."), React.createElement("p", undefined, "I enjoy making tools and working with programming languages. I usually work on product infrastructure, thinking about code ergonomics and scalable product engineering."), React.createElement("p", undefined, "This site is a work in progress. I err on the side of publishing early.")), React.createElement(Grid$Paulshen.Root.make, {
                                      children: React.createElement(Grid$Paulshen.Cell.make, {
                                            span: 6,
                                            children: null
                                          }, React.createElement(MonoTable$Paulshen.make, {
                                                label: "Interests",
                                                children: null,
                                                className: metadata
                                              }, React.createElement(MonoTable$Paulshen.Row.make, {
                                                    value: "Programming languages"
                                                  }), React.createElement(MonoTable$Paulshen.Row.make, {
                                                    value: "Code and design tools"
                                                  }), React.createElement(MonoTable$Paulshen.Row.make, {
                                                    value: "Buddhism and meditation"
                                                  }), React.createElement(MonoTable$Paulshen.Row.make, {
                                                    value: "Architecture"
                                                  }), React.createElement(MonoTable$Paulshen.Row.make, {
                                                    value: "Nature"
                                                  })), React.createElement(MonoTable$Paulshen.make, {
                                                label: "Likes",
                                                children: null,
                                                className: metadata
                                              }, React.createElement(MonoTable$Paulshen.Row.make, {
                                                    label: "Editor",
                                                    value: "vim + VS Code"
                                                  }), React.createElement(MonoTable$Paulshen.Row.make, {
                                                    label: "Beer",
                                                    value: "IPA"
                                                  }), React.createElement(MonoTable$Paulshen.Row.make, {
                                                    label: "Scent",
                                                    value: "Hinoki"
                                                  }), React.createElement(MonoTable$Paulshen.Row.make, {
                                                    label: "Beverage",
                                                    value: "Kombucha"
                                                  }), React.createElement(MonoTable$Paulshen.Row.make, {
                                                    label: "Food",
                                                    value: "Natto"
                                                  })))
                                    })),
                            fullWidthOnMobile: true
                          }), React.createElement(Grid$Paulshen.Cell.make, {
                            span: 1,
                            fullWidthOnMobile: true
                          }), React.createElement(Grid$Paulshen.Cell.make, {
                            span: 4,
                            className: rightPane,
                            children: null,
                            fullWidthOnMobile: true
                          }, React.createElement(Text$Paulshen.MonoHeader.make, {
                                children: "On the web",
                                size: /* S1 */18558,
                                tag: /* H3 */16107
                              }), React.createElement(Text$Paulshen.Body.make, {
                                children: React.createElement("ul", {
                                      className: textList
                                    }, React.createElement("li", undefined, React.createElement("a", {
                                              href: "https://twitter.com/_paulshen"
                                            }, "Twitter")), React.createElement("li", undefined, React.createElement("a", {
                                              href: "https://github.com/paulshen"
                                            }, "Github")), React.createElement("li", undefined, React.createElement("a", {
                                              href: "https://instagram.com/_pauldraws"
                                            }, "Instagram")))
                              })))),
              title: "About"
            });
}

var make = AboutPage;

var slug = "/about";

var $$default = AboutPage;

exports.Styles = Styles;
exports.make = make;
exports.slug = slug;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* body Not a pure module */
